<template>
  <div class="ssh">
    <div id="xterm" class="xterm" style="width:100%;height:100%" />
  </div>

</template>

<script>
import 'xterm/css/xterm.css'
import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import { AttachAddon } from 'xterm-addon-attach'
import { ElMessage } from 'element-plus'
export default {
  props: ['wsAddress'],
  data() {
    return {
      isReloadData: true,
      sessionSwitch: false,
      vncSwitch: false,
      loading: true,
      uId: '',
      default: '',
      connect: '',
      token: '',
      style: {
        height: '',
        width: ''
      },
      windowHeight: '',
      windowWidth: '',
      fitAddon: ''
    }
  },
  mounted() {
    this.initSocket()
    const that = this
    const elementResizeDetectorMaker = require('element-resize-detector')// 导入
    // 创建实例
    const erd = elementResizeDetectorMaker()
    // 创建实例带参
    const erdUltraFast = elementResizeDetectorMaker({
      strategy: 'scroll', // <- For ultra performance.
      callOnAdd: true,
      debug: true
    })
    // 监听id为test的元素 大小变化
    erd.listenTo(document.querySelector('#ssh'), function(element) {
      // var width = element.offsetWidth;
      // var height = element.offsetHeight;
      // that.style.height = height
      // that.style.width = width
      // console.log(that.style.height)
      try {
        that.$nextTick(() => {
          that.fitAddon.fit()
        })
      } catch (error) {
        console.log(error)
      }
    })
  },
  beforeUnmount() {
    try {
      this.socket.close()
      this.term.dispose()
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    reload() {
      this.isReloadData = false
      this.$nextTick(() => {
        this.isReloadData = true
      })
    },
    initTerm() {
      const term = new Terminal({
        scrollback: 500,
        fontSize: 14,
        cursorBlink: true,
        rendererType: 'canvas',
        // 启用时，光标将设置为下一行的开头
        convertEol: false,
        theme: {
          cursor: 'help'
        }
      })
      const attachAddon = new AttachAddon(this.socket)
      const fitAddon = new FitAddon()
      term.loadAddon(attachAddon)
      term.loadAddon(fitAddon)
      term.open(document.getElementById('xterm'))
      fitAddon.fit()
      this.fitAddon = fitAddon
      term.focus()
      term.attachCustomKeyEventHandler(e => {
        // console.log('listen press key ' + e.keyCode)
        if (e.ctrlKey && e.keyCode === 76) {
          term.clear()
          fitAddon.fit()
        } else if (e.ctrlKey && e.keyCode === 68) {
          // this.term.dispose()
          this.socket.close()
          // term.clear()
          term.writeln('disconnect')
        }
      })
      term.onData(cmd => {
      })
      // 限制和后端交互，只有输入回车键才显示结果
      term.prompt = () => {
        term.write('\r\n$ ')
      }

      term.prompt()
      this.term = term
    },
    initSocket() {
      this.token = sessionStorage.desktopToken
      // const url = this.wsAddress + '&token=' + this.token
      // const url = 'wss:' + location.host + '/sshSocket/ws/vnc/sshConnect?connect=' + this.wsAddress + '&token=' + this.token
      var url
      var ishttps = document.location.protocol == 'https:'
      if (ishttps) {
        url = 'wss:' + location.host + '/sshSocket/ws/vnc/sshConnect?connect=' + this.wsAddress + '&token=' + this.token
      } else {
        url = 'ws:' + location.host + '/sshSocket/ws/vnc/sshConnect?connect=' + this.wsAddress + '&token=' + this.token
      }
      this.socket = new WebSocket(url)
      this.socketOnClose()
      this.socketOnOpen()
      this.socketOnError()
    },
    socketOnOpen() {
      this.socket.onopen = () => {
        // 链接成功后
        this.vncSwitch = true
        this.initTerm()
      }
    },
    socketClose() {
      this.vncSwitch = false
      this.socket.close()
    },
    socketOnClose() {
      this.socket.onclose = () => {
        console.log('close socket')
      }
    },
    socketOnError() {
      this.vncSwitch = false
      this.socket.onerror = () => {
        console.log('socket 连接失败')
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .ssh{
    width: 100%;
    height: calc(100% - 20px);
  }
</style>
