<template>
  <div class="ehpc">
    <div class="container">
      <div v-show="showTalk" class="left">
        <div class="title">
          <div>所有集群</div>
          <el-button @click="addCluster"><svg-icon icon-class="add-slurm" />创建集群</el-button>
        </div>
        <div v-for="(item, i) in talkList" :key="i" class="slurm">
          <el-tooltip popper-class="atooltip" placement="right" effect="light">
            <template #content>
              <div class="slurm-address">{{ item.fID }}</div>
              <div class="slurm-hover-name" @click="choiceHandle(item,'ssh')"><div class="circles" /><div>SSH</div></div>
              <div class="slurm-hover-name" @click="choiceVnc(item)"><div class="circles" /><div>VNC</div></div>
              <div v-if="item.status!==3" class="slurm-hover-name" @click="delCluster(item)"><div class="circles" /><div>删除集群</div></div>
            </template>
            <div
              class="list"
              :class="{ active: i == activeColor }"
              @dblclick="clickHandle(i,item)"
            >
              <svg-icon :icon-class="[i == activeColor ? 'cluster-on' : 'cluster']" />
              <div style="width:155px">{{ item.name }}</div>
              <div v-show="item.status === 4" class="circles fail-circle" />
              <div v-show="item.status === 601 || item.status === 602 || item.status === 603 || item.status === 604 || item.status === 605 || item.status === 606" class="circles fail-circle" />
              <div v-show="item.status === 1" class="circles normal-circle" />
              <div v-show="item.status === 2" class="m-spin-dot">
                <span class="u-dot-item normal-circle" />
                <span class="u-dot-item normal-circle" />
                <span class="u-dot-item normal-circle" />
                <span class="u-dot-item normal-circle" />
              </div>
              <div v-show="item.status === 3" class="m-spin-dot">
                <span class="u-dot-item fail-circle" />
                <span class="u-dot-item fail-circle" />
                <span class="u-dot-item fail-circle" />
                <span class="u-dot-item fail-circle" />
              </div>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="middle">
        <div class="control" @click="showHandle">
          <img src="@/assets/icon/arrow.png" alt="">
        </div>
      </div>
      <div v-show="sessionShow" id="ssh" class="session-box">
        <div class="min-size">
          <div class="svg-box" @click="miniSshHandle"><svg-icon icon-class="mini" /></div>
          <div class="svg-box" @click="closeSshHandle"><svg-icon icon-class="close" /></div>
        </div>
        <ssh v-if="wsAddress!==''" :ws-address="wsAddress" />
      </div>
      <div v-show="!slurmLoading && !sessionShow" id="right" class="right">
        <div ref="tabs" class="tabs">
          <div v-for="(item,i) in editableTabs" :key="i" class="tabs-item" :class="{ activeTab: i === activeTabColor }">
            <div style="width:150px" @click="changeTab(item,i)">{{ item.title }}</div>
            <div class="svg-bg" @click="closeTab(item,i)">
              <svg-icon icon-class="close" />
            </div>
          </div>
        </div>
        <div class="header">
          <div class="box">
            <div class="image">
              <img src="@/assets/icon/node.png" alt="">
            </div>
            <div class="text">
              <div class="num">{{ nodeCount }}</div>
              <div class="desc">节点数（个）</div>
            </div>
          </div>
          <div class="box">
            <div class="image">
              <img src="@/assets/icon/core.png" alt="">
            </div>
            <div class="text">
              <div class="num">{{ cpuCount }}</div>
              <div class="desc">核心数（个）</div>
            </div>
          </div>
          <div class="box">
            <div class="image">
              <img src="@/assets/icon/job.png" alt="">
            </div>
            <div class="text">
              <div class="num">{{ jobCount }}</div>
              <div class="desc">作业数（个）</div>
            </div>
          </div>
          <div class="box">
            <div class="image">
              <img src="@/assets/icon/queue.png" alt="">
            </div>
            <div class="text">
              <div class="num">{{ queueCount }}</div>
              <div class="desc">队列数（个）</div>
            </div>
          </div>
        </div>
        <div class="form">
          <div class="form-content">
            <div class="btn">
              <el-button @click="submitHandle"><svg-icon icon-class="submit" />提交</el-button>
            </div>
            <el-form ref="addForm" :rules="rules" :model="addForm" size="small">
              <div class="content">
                <div class="coloumn">
                  <el-form-item prop="jobName" label="作业名称" label-width="6em">
                    <el-input v-model="addForm.jobName" />
                  </el-form-item>
                  <el-form-item prop="jobQueue" label="作业队列" label-width="6em">
                    <!-- <el-input v-model="addForm.jobQueue" /> -->
                    <el-select v-model="addForm.jobQueue" placeholder="请选择" @change="handleChange">
                      <el-option v-for="item in jobOptions" :key="item.name" :label="item.name" :value="item.name" />
                    </el-select>
                  </el-form-item>
                  <el-form-item prop="process" label="节点进程数" label-width="6em">
                    <el-input-number v-model="addForm.process" :min="1" :step="1" />
                  </el-form-item>
                  <el-form-item prop="node" label="节点数" label-width="6em">
                    <el-input-number v-model="addForm.node" :min="1" :step="1" />
                  </el-form-item>
                </div>
                <div class="coloumn">
                  <el-form-item prop="dir" class="data-dir" label="数据目录" label-width="6em">
                    <el-input
                      v-model="addForm.dir"
                      readonly
                      style="cursor:pointer"
                      placeholder="点击选择数据目录"
                      @click="changDirs"
                    />
                  </el-form-item>
                  <el-form-item prop="script" label="执行脚本">
                    <el-input v-model="addForm.script" :rows="6" type="textarea" />
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <div>
            <div v-for="(item,i) in modeList" :key="i" class="mode" :class="{ actives: i === activesColor }" @click="clickModeHandle(i,item)">{{ item }}</div>
          </div>
        </div>
        <div class="list">
          <el-table
            ref="multipleTable"
            v-loading="jobLoading"
            :data="jobList"
            tooltip-effect="dark"
            style="width: 100%; height: auto"
            :row-style="tableCellJobStyle"
            @row-click="rowJobClick"
            @row-contextmenu="rightClick"
          >
            <el-table-column prop="job_id" label="作业ID" show-overflow-tooltip />
            <el-table-column
              prop="name"
              label="作业名称"
              show-overflow-tooltip
            />
            <el-table-column
              prop="time.start"
              :formatter="formatsTime"
              label="运行时间"
              show-overflow-tooltip
            />
            <el-table-column prop="state.current" label="作业状态" show-overflow-tooltip>
              <template #default="scope">
                <div
                  v-if="scope.row.state.current === 'Priority'"
                  class="state_circles"
                  style="color: #2385ff"
                >
                  <div class="circles" style="background-color: #2385ff" />
                  <div>运行中</div>
                </div>
                <div
                  v-else-if="scope.row.state.current === 'Resources'"
                  class="state_circles"
                  style="color: #15a675"
                >
                  <div class="circles" style="background-color: #15a675" />
                  <div>已完成</div>
                </div>
                <div
                  v-else
                  class="state_circles"
                  style="color: #fd8e3f"
                >
                  <div class="circles" style="background-color: #fd8e3f" />
                  <div>异常</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-show="slurmLoading && !sessionShow" class="slurm-loading">
        <div>
          <img src="@/assets/icon/ehpc.png" alt="">
          <div v-show="choiceText" class="loading-text">请双击集群列表选择集群</div>
          <div v-show="arrangeText" class="loading-text">集群部署中，请耐心等待......</div>
          <div v-show="delText" class="loading-text">删除中，请耐心等待......</div>
          <div v-show="queryText" class="loading-text">查询中，请耐心等待......</div>
          <div v-show="noDataText" class="loading-text">暂无数据</div>
          <div v-show="failText" class="loading-text">部署失败</div>
          <div v-show="errorText" class="loading-text">资源不足，请稍后</div>
        </div>
      </div>
    </div>

    <!-- 选择数据目录 -->
    <div class="dialog-container">
      <el-dialog
        v-model="dirFormVisible"
        title="选择目录"
        :close-on-click-modal="false"
        :before-close="handleClose"
        width="50%"
      >
        <div v-loading="loadingDialog" class="dialog-content">
          <div class="left">
            <el-tree
              ref="tree"
              :data="data"
              :props="defaultProps"
              icon-class="el-icon-arrow-right"
              @node-click="handleNodeClick"
            >
              <template #default="{ node }">
                <span class="custom-tree-node">
                  <span><svg-icon style="margin: 0 12px" icon-class="files" /></span>
                  <span>{{ node.label }}</span>
                </span>
              </template>
            </el-tree>
          </div>
          <div class="right">
            <el-table
              ref="multipleTable"
              :data="filesData"
              tooltip-effect="dark"
              style="width: 100%"
              :row-style="tableCellStyle"
              :row-class-name="tableRowClassName"
              @row-click="rowClick"
              @row-dblclick="rowDbClick"
              @cell-mouse-enter="mousePass"
            >
              <el-table-column prop="name" label="名称" show-overflow-tooltip />
              <!-- <el-table-column prop="size" label="大小" show-overflow-tooltip /> -->
              <el-table-column prop="size" label="大小" show-overflow-tooltip>
                <template #default="scope">
                  {{ scope.row.size }}Bytes
                </template>
              </el-table-column>
              <el-table-column prop="type" label="文件类型" show-overflow-tooltip>
                <template #default="scope">
                  <div>
                    <div v-if="scope.row.type === ''" style="display: inline-block"><svg-icon icon-class="file" /></div>
                    <div v-else-if="scope.row.type === 'cmd'" style="display: inline-block"><svg-icon icon-class="cmd" /></div>
                    <div v-else-if="scope.row.type === 'md'" style="display: inline-block"><svg-icon icon-class="md" /></div>
                    <div v-else-if="scope.row.type === 'text'" style="display: inline-block"><svg-icon icon-class="txt" /></div>
                    <div v-else-if="scope.row.type === 'zip'" style="display: inline-block"><svg-icon icon-class="zip" /></div>
                    <div v-else-if="scope.row.type === 'image'" style="display: inline-block"><svg-icon icon-class="img" /></div>
                    <div v-else><svg-icon icon-class="other" /></div>
                    <span v-show="scope.$index === currentRow" style="padding-left: 5px;color: #0949ee" @click="clickDir()">确定</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="modified"
                label="修改日期"
                :formatter="formatTime"
                show-overflow-tooltip
              />
            </el-table>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 创建集群 -->
    <div class="dialog-container">
      <el-dialog
        v-model="addFormVisible"
        title="创建集群"
        :close-on-click-modal="false"
        :before-close="handleCloseAdd"
        width="35%"
      >
        <div class="dialogs-content">
          <el-form ref="formRef" :rules="addRules" :model="form">
            <el-form-item label="集群名称" prop="name" label-width="8em">
              <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item prop="node" label="计算节点台数" label-width="8em">
              <el-input-number v-model="form.node" :min="1" :step="1" />
            </el-form-item>
            <el-form-item label="实例规格" prop="tip" label-width="8em">
              <el-select v-model="form.tip" placeholder="实例规格">
                <el-option label="2核4G" value="2核4G" />
              </el-select>
            </el-form-item>
            <el-form-item prop="ha" label="是否需要HA" label-width="8em">
              <el-radio-group v-model="form.ha">
                <el-radio label="true">是</el-radio>
                <el-radio label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item style="text-align:right">
              <el-button type="primary" @click="confirmCluster">创建</el-button>
              <el-button @click="cancel">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <!-- 删除 -->
    <div class="dialog-container">
      <el-dialog
        v-model="delFormVisible"
        title="删除集群"
        :close-on-click-modal="false"
        :before-close="handleCloseDel"
        width="35%"
      >
        <div class="dialogs-content">
          <el-alert
            title="确认要删除该集群么？"
            type="info"
            :closable="false"
            show-icon
          />
          <el-form ref="delForm" :rules="delRules" :model="delForm" size="small">
            <div class="dialog-form">
              <el-form-item label=" " prop="del">
                <el-checkbox-group v-model="delForm.del">
                  <el-checkbox label="强制删除" name="del" />
                </el-checkbox-group>
              </el-form-item>
              <el-form-item style="text-align:right">
                <el-button :loading="delConfirm" type="primary" @click="handleConfirmDel">确定</el-button>
                <el-button @click="handleCancelDel">取消</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <div v-show="menuVisible" id="contextmenu" class="jobMenu">
      <div class="contextmenu-item" @click="logRight(CurrentRow)">
        <svg-icon icon-class="log-look" />
        查看日志
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import ssh from '@/components/ssh.vue'
import { queryEhpcList, slurmInfo, slurmDetail, slurmConnect, slurmList, submitSlurm, queueList, addEhpcCluster, deleteEhpcCluster } from '@/api/ehpc'
import {
  filesList,
  dirsList
} from '@/api/file'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  components: {
    ssh
  },
  data() {
    const validateName = (rule, value, callback) => {
      const regExp = /^[\u4e00-\u9fa5a-z-_.A-Z0-9]+$/
      if (value.trim().length < 1) {
        callback(new Error('集群名称不能为空'))
      } else {
        if (regExp.test(value.trim()) === false) {
          callback(new Error('集群名称仅可以包含中英文数字-_.'))
        } else if (value.trim().length < 2 || value.trim().length > 50) {
          callback(new Error('集群名称长度应在2-50个字符之间'))
        } else {
          callback()
        }
      }
    }
    const validateJobName = (rule, value, callback) => {
      const regExp = /^[a-z\d][a-z\d\-_]*[a-z\d]$/
      if (value.trim() === '') {
        callback(new Error('请输入作业名称'))
      } else {
        if (value.trim().length < 4 || value.trim().length > 15) {
          callback(new Error('作业名称应为4-15个字符'))
        } else {
          if (regExp.test(value.trim()) === false) {
            callback(new Error('作业名称仅可包含小写字母数字破折号(-)和下划线(_)且只能以字母数字作为开头结尾'))
          } else {
            callback()
          }
        }
      }
    }
    return {
      menuVisible: false,
      wsAddress: '',
      // style:{
      //   bottom:0,
      //   left:0
      // },
      sessionShow: false,
      slurmLoading: true,
      arrangeText: false,
      errorText: false,
      delText: false,
      queryText: false,
      choiceText: true,
      noDataText: false,
      clusterId: '',
      nodeCount: 0,
      cpuCount: 0,
      jobCount: 0,
      queueCount: 0,
      modeList: ['交互模式', '进阶模式'],
      activeTabColor: 0,
      // tabs
      editableTabsValue: '2',
      editableTabs: [],
      timer: null,
      addTimer: null,
      interval: '',
      tabIndex: 2,
      uId: '',
      token: '',
      loading: true,
      jobLoading: false,
      showTalk: true,
      boxShow: false,
      activeColor: null,
      activesColor: 0,
      modeType: '',
      talkList: [],
      currentRow: -1,
      loadingDialog: false,
      dirFormVisible: false,
      addLoadingDialog: false,
      addFormVisible: false,
      filesData: [],
      tableData: [],
      data: [],
      path: '',
      queue: '',
      row: '',
      jobOptions: [],
      defaultProps: {
        children: 'childDir',
        label: 'name'
      },
      delConfirm: false,
      delFormVisible: false,
      delForm: {
        del: []
      },
      delRules: {
        del: [
          { type: 'array', required: true, message: '请确认是否强制删除该集群', trigger: 'change' }
        ]
      },
      form: {
        ha: 'false',
        name: '',
        tip: '2核4G',
        node: ''
      },
      addRules: {
        name: [{ required: true, trigger: 'blur', validator: validateName }],
        node: [{ required: true, trigger: 'change', message: '请选择计算节点台数' }],
        ha: [{ required: true, trigger: 'change', message: '请选择是否需要HA' }],
        tip: [{ required: true, trigger: 'change', message: '请选择实例规格' }]
      },
      rules: {
        jobName: [{ required: true, trigger: 'blur', validator: validateJobName }],
        jobQueue: [{ required: true, trigger: 'change', message: '请选择作业队列' }],
        dir: [{ required: true, trigger: 'change', message: '请选择目录' }],
        process: [{ required: true, trigger: 'change', message: '请选择节点进程数' }],
        node: [{ required: true, trigger: 'change', message: '请选择节点数' }],
        script: [{ required: true, trigger: 'blur', message: '请输入执行脚本' }]
      },
      addForm: {
        jobName: '',
        jobQueue: '',
        script: '',
        dir: '',
        process: 1,
        node: 0
      },
      clusterLength: 0,
      rowJob: '',
      jobList: [],
      pageSize: 4,
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [4, 8, 16, 20],
      clusterList: [],
      clusterStatus: null,
      isQuery: false
    }
  },
  watch: {
    'clusterList.length': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.talkList = this.clusterList
          this.slurmLoading = true
          if (newValue < oldValue) {
            if (newValue === 0) {
              this.noDataText = true
              this.arrangeText = false
              this.queryText = false
              this.delText = false
              this.arrangeText = false
              this.choiceText = false
              this.failText = false
              this.errorText = false
              return
            }
            // this.clusterId = localStorage.clusterId
            // this.$nextTick(() => {
            //   this.slurmDetail()
            // })
          }
          this.arrangeText = false
          this.queryText = false
          this.delText = false
          this.arrangeText = false
          this.failText = false
          this.errorText = false
          this.choiceText = true
          this.noDataText = false
          this.activeColor = null
        }
      },
      deep: true
    }
  },
  mounted() {
    // this.activeColor = localStorage.activeCluster
    // this.clusterId = localStorage.clusterId
    this.uId = store.getters.uId
    this.getSlurm()
    this.getDirsList()
    this.getFiles()
    this.directory = '/public/home/' + store.getters.name
    this.path = this.directory + '/'
    this.setIntervalHandle()
  },
  beforeUnmount() {
    clearInterval(this.timer)
    this.timer = null
    clearInterval(this.addTimer)
    this.addTimer = null
  },
  methods: {
    // 右键
    rightClick(row, column, event) {
      this.testModeCode = row.testModeCode
      this.menuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault() // 关闭浏览器右键默认事件
      this.CurrentRow = row
      var menu = document.querySelector('.jobMenu')
      this.styleMenu(menu, event)
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      window.removeEventListener('click', this.foo) // 关掉监听，
    },
    styleMenu(menu, event) {
      var y = event.clientY
      var x = event.clientX
      menu.style.left = x + 'px'
      window.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      menu.style.top = y + 'px'
    },
    // 作业列表 查看日志
    logRight(row) {
      this.row = row
      localStorage.workDir = this.row.working_directory
      localStorage.idJob = this.row.job_id
      this.$nextTick(() => {
        this.$store.dispatch('core/openApp', 6372403323111392)
      })
    },
    queryEhpcList() {
      queryEhpcList(this.uId).then((response) => {
        this.clusterList = response.data.clusterList
        if (this.clusterList.length === 0) {
          this.noDataText = true
          this.queryText = false
          this.arrangeText = false
          this.delText = false
          this.choiceText = false
          // localStorage.activeCluster = null
          // localStorage.clusterId = ''
        }
      })
    },
    setIntervalHandle() {
      // const that = this
      // that.timer = setInterval(() => {
      //   this.queryEhpcList()
      // }, 1000)
      const that = this
      that.addTimer = setInterval(() => {
        that.getSlurm()
      }, 2000)
    },
    handleChange(val) {
      this.addForm.jobQueue = val
    },
    // 切换tab
    changeTab(item, i) {
      this.activeTabColor = i
      this.wsAddress = item.wsAddress
      this.sessionShow = true
    },
    // 关闭tab
    closeTab(item, i) {
      this.editableTabs.splice(i, 1)
      this.wsAddress = ''
    },
    // 作业
    rowJobClick(row) {
      this.rowJob = row
    },
    tableCellJobStyle(row) {
      if (this.rowJob === row.row) {
        return 'background-color:#f5f6fa;'
      } else {
        return 'background-color:#fff;'
      }
    },
    // 时间格式
    // 时间格式化
    formatTime(row, column) {
      const data = row[column.property]
      const dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hour = dtime.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      let minute = dtime.getMinutes()
      if (minute < 10) {
        minute = '0' + minute
      }
      let second = dtime.getSeconds()
      if (second < 10) {
        second = '0' + second
      }
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      )
    },
    formatsTime(row, column) {
      const dtime = new Date(row.time.start * 1000)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hour = dtime.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      let minute = dtime.getMinutes()
      if (minute < 10) {
        minute = '0' + minute
      }
      let second = dtime.getSeconds()
      if (second < 10) {
        second = '0' + second
      }
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      )
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    mousePass(row, column, cell, event) {
      this.currentRow = row.index
    },
    clickDir() {
      this.dirFormVisible = false
    },
    changDirs() {
      this.dirFormVisible = true
    },
    getDirsList() {
      this.loadingDialog = true
      dirsList(this.path).then((response) => {
        const data = [
          { name: response.data.name, path: response.data.path, childDir: [] }
        ]
        data[0].childDir = response.data.childDir
        this.data = data
        this.loadingDialog = false
      })
    },
    // 文件列表
    getFiles() {
      if (this.data.length !== 0) {
        this.loadingDialog = true
      }
      filesList(this.path)
        .then((response) => {
          if (this.data.length !== 0) {
            this.loadingDialog = false
          }

          const dirs = []
          response.data.items.forEach(item => {
            if (item.isDir) {
              dirs.push(item)
            }
          })
          this.filesData = dirs
        })
        .catch(() => {
          this.path = this.nextPath
        })
    },
    // 点击节点事件
    handleNodeClick(data) {
      this.path = data.path
      this.$nextTick(() => {
        this.getFiles()
      })
    },
    rowClick(row) {
      this.row = row
      if (this.row.isDir !== true) {
        ElMessage.warning('该数据不是目录，请选择正确的目录')
      } else {
        this.addForm.dir = this.row.path
      }
      // this.dirFormVisible = false
    },
    tableCellStyle(row) {
      if (this.row === row.row) {
        return 'background-color:#f5f6fa;'
      } else {
        return 'background-color:#fff;'
      }
    },
    // 打开新的文件夹或者下载文件
    rowDbClick(row) {
      if (row.isDir) {
        this.path = row.path
        this.getFiles()
      }
    },
    clickModeHandle(i, item) {
      this.activesColor = i
      this.modeType = item
    },
    // 点击集群列表
    async clickHandle(i, item) {
      this.slurmLoading = true
      // this.sessionShow = false
      this.nodeCount = 0
      this.cpuCount = 0
      this.jobCount = 0
      this.queueCount = 0
      this.editableTabs = []
      this.jobOptions = []
      this.jobList = []
      this.jobLoading = true
      this.activeColor = i
      this.clusterId = item.clusterId
      // 0-未部署  1-部署完成 2-正在部署 4-部署失败
      this.clusterStatus = item.status
      if (this.clusterStatus === 1) {
        this.queryText = true
        this.choiceText = false
        this.noDataText = false
        this.arrangeText = false
        this.delText = false
        this.failText = false
        this.errorText = false
        this.slurmDetailHandle()
      }
      if (this.clusterStatus === 0 || this.clusterStatus === 2) {
        this.queryText = false
        this.choiceText = false
        this.noDataText = false
        this.arrangeText = true
        this.delText = false
        this.failText = false
        this.errorText = false
        this.isQuery = true
      }
      if (this.clusterStatus === 3) {
        this.queryText = false
        this.choiceText = false
        this.noDataText = false
        this.arrangeText = false
        this.delText = true
        this.errorText = false
        this.failText = false
      }
      if (this.clusterStatus === 4) {
        this.queryText = false
        this.choiceText = false
        this.noDataText = false
        this.arrangeText = false
        this.delText = false
        this.errorText = false
        this.failText = true
      }
      if (this.clusterStatus === 601 || this.clusterStatus === 602 || this.clusterStatus === 603 || this.clusterStatus === 604 || this.clusterStatus === 605 || this.clusterStatus === 606) {
        this.queryText = false
        this.choiceText = false
        this.noDataText = false
        this.arrangeText = false
        this.delText = false
        this.failText = false
        this.errorText = true
        ElMessage.error('资源不足，请稍后')
      }
    },
    // 查询集群详情
    slurmDetail() {
      // if (localStorage.clusterId !== '') {
      this.clusterList.forEach((item) => {
        if (this.clusterId == item.clusterId) {
          this.clusterStatus = item.status
          if (this.clusterStatus === 1) {
            this.queryText = true
            this.choiceText = false
            this.noDataText = false
            this.arrangeText = false
            this.delText = false
            this.failText = false
            this.errorText = false
            this.slurmDetailHandle()
          }
          if (this.clusterStatus === 0 || this.clusterStatus === 2) {
            this.queryText = false
            this.choiceText = false
            this.noDataText = false
            this.arrangeText = true
            this.delText = false
            this.errorText = false
            this.failText = false
          }
          if (this.clusterStatus === 4) {
            this.queryText = false
            this.choiceText = false
            this.noDataText = false
            this.arrangeText = false
            this.delText = false
            this.errorText = false
            this.failText = true
          }
          if (this.clusterStatus === 601 || this.clusterStatus === 602 || this.clusterStatus === 603 || this.clusterStatus === 604 || this.clusterStatus === 605 || this.clusterStatus === 606) {
            this.queryText = false
            this.choiceText = false
            this.noDataText = false
            this.arrangeText = false
            this.delText = false
            this.failText = false
            this.errorText = true
            ElMessage.error('资源不足，请稍后')
          }
        }
      })
    },
    slurmDetailHandle() {
      slurmInfo(this.clusterId).then((response) => {
        this.nodeCount = response.data.data.nodeCount
        this.cpuCount = response.data.data.cpuCount
        this.jobCount = response.data.data.jobCount
        this.queueCount = response.data.data.queueCount
      })
      queueList(this.clusterId).then((response) => {
        this.jobOptions = response.data.queue
      })
      this.slurmList()
      this.slurmLoading = false
      this.isQuery = false
    },
    // 作业列表
    slurmList() {
      slurmList(this.clusterId).then((response) => {
        this.jobList = response.data.jobs
        this.jobLoading = false
      })
    },

    // 提交作业
    submitHandle() {
      const data = {
        current_working_directory: this.addForm.dir.trim(),
        name: this.addForm.jobName.trim(),
        partition: this.addForm.jobQueue.trim(),
        script: this.addForm.script.trim(),
        tasks: this.addForm.process * this.addForm.node
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.jobLoading = true
          submitSlurm(data, this.clusterId).then((response) => {
            if (response.meta.status === 201) {
              ElMessage.success(response.meta.msg)
              this.$nextTick(() => {
                this.slurmDetailHandle()
                this.$refs.addForm.resetFields()
              })
            } else {
              this.jobLoading = false
              ElMessage.error(response.meta.msg)
            }
          }).catch(() => {
            this.jobLoading = false
          })
        }
      })
    },
    // ssh最小化
    miniSshHandle() {
      this.sessionShow = false
    },
    closeSshHandle() {
      this.sessionShow = false
      this.wsAddress = ''
      this.editableTabs.pop()
    },
    // 选择ssh vnc
    choiceHandle(item) {
      this.wsAddress = ''
      slurmConnect(item.clusterId).then((response) => {
        this.editableTabs.push({ title: 'SSH', wsAddress: response.data })
        this.wsAddress = response.data
        this.sessionShow = true
      })
    },
    // 选择vnc
    choiceVnc(item) {
      this.$store.dispatch('core/openApp', 2042411752769493)
    },
    // 取消创建
    cancel() {
      this.addFormVisible = false
      this.$refs.formRef.resetFields()
    },
    // 确定创建
    confirmCluster() {
      const data = {
        clusterName: this.form.name.trim(),
        eclusterNum: this.form.node,
        masterHA: this.form.ha.trim(),
        uid: localStorage.uId
      }
      this.noDataText = false
      this.choiceText = false
      this.failText = false
      this.errorText = false
      this.slurmLoading = true
      this.sessionShow = false
      this.arrangeText = true
      this.queryText = false
      this.delText = false
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          addEhpcCluster(data).then((response) => {
            if (response.meta.status === 201) {
              this.clusterId = response.data.clusterId
              ElMessage.success(response.meta.msg)
              this.activeColor = null
            } else {
              ElMessage.error(response.meta.msg)
            }
            this.addFormVisible = false
            this.$refs.formRef.resetFields()
          }).catch(() => {
            this.addFormVisible = false
            this.$refs.formRef.resetFields()
          })
        }
      })
    },
    // 创建集群
    addCluster() {
      this.addFormVisible = true
    },
    // 鼠标移入
    enterHandle(i, item) {
      this.activeColor = i
      this.boxShow = true
    },
    showHandle() {
      this.showTalk = !this.showTalk
    },
    // 删除集群
    delCluster(item) {
      this.clusterId = item.clusterId
      this.delFormVisible = true
    },
    // 删除 关闭
    handleCloseDel() {
      this.delFormVisible = false
      this.$refs.delForm.resetFields()
    },
    // 删除取消
    handleCancelDel() {
      this.delFormVisible = false
      this.$refs.delForm.resetFields()
    },
    // 删除 确定
    handleConfirmDel() {
      this.delConfirm = true
      // this.loading = true
      this.activeColor = null
      this.queryText = false
      this.arrangeText = false
      this.failText = false
      this.errorText = false
      this.choiceText = false
      this.slurmLoading = true
      this.sessionShow = false
      this.delText = true
      this.$refs.delForm.validate((valid) => {
        if (valid) {
          deleteEhpcCluster(this.clusterId).then((response) => {
            if (response.meta.status === 204) {
              ElMessage.success(response.meta.msg)
              // this.$nextTick(() => {
              //   this.setIntervalHandle()
              // })
            } else {
              ElMessage.error(response.meta.msg)
            }
          })
          this.delFormVisible = false
          this.$refs.delForm.resetFields()
          this.delConfirm = false
        } else {
          this.delConfirm = false
        }
      })
    },
    getSlurm() {
      queryEhpcList(this.uId).then((response) => {
        this.clusterLength = response.data.clusterList.length
        this.talkList = response.data.clusterList
        this.clusterList = response.data.clusterList
        if (response.data.clusterList.length === 0) {
          this.noDataText = true
          this.queryText = false
          this.arrangeText = false
          this.choiceText = false
          this.delText = false
          return
        }
        this.clusterList.forEach((item) => {
          if (this.clusterId === item.clusterId) {
            if (item.status === 1 && this.isQuery) {
              this.slurmDetailHandle()
            }
          }
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ehpc{
  width: 100%;
  height: 100%;
  .jobMenu {
  position: fixed;
  background-color: #fff;
  width: 160px;
	padding: 0 10px;
  height: 33px;
  font-size: 12px;
  color: #313a46;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #E8EEF3;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  white-space: nowrap;
  z-index: 10000;
	.svg-icon{
		width: 16px;
		height: 16px;
		vertical-align: middle;
		margin-right: 11px;
	}
}
  .el-alert--info.is-light{
    background-color: #fff;
    color: #606266;
  }
  .session-box{
    width: 100%;
    height: 100%;
    .min-size{
      width: 100%;
      height: 20px;
      line-height: 20px;
      background-color: #f5f5f5;
      display: flex;
      justify-content: flex-end;
      .svg-box{
        padding: 0 15px;
      }
      .svg-box:hover{
        background-color: #BBBBBB;
      }
    }
  }
  .slurm-loading{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #606060;
    font-size: 16px;
    .loading-text{
      margin-top: 25px;
      width: 253px;
      text-align: center;
    }
  }
  ::v-deep .el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus{
    border-color: #dcdfe6;
  }
  .pagination{
    ::v-deep .el-icon{
      color: #fff;
    }
  }
  ::v-deep .el-dialog{
    max-height: 500px;
    .el-dialog__body{
      max-height: 500px;
    }
  }
  .dialogs-content{
    padding: 20px;
  }
  .dialog-content{
    display: flex;
    font-size: 12px;
    .left {
      overflow: auto;
      min-width: 220px;
      // width: 100%;
      // height: 80%;
      max-height: calc(500px - 54.44px);
      box-sizing: border-box;
      font-weight: 500;
      font-size: 14px;
      padding: 20px;
      color: #313a46;
      border-right: 1px solid #d8d8d8;
      ::v-deep .el-tree-node.is-expanded > .el-tree-node__children {
        display: inline;
      }
    }
    .right {
      width: 100%;
      padding: 20px;
      max-height: calc(500px - 54.44px);
      color: #202020;
      ::v-deep .el-table {
        height: 100%;
        overflow: auto;
      }
    }
  }
  .container{
    width: 100%;
  height: 100%;
    display: flex;
    .left{
    min-width: 240px;
    max-width: 400px;
    height: 100%;
    overflow: auto;
    color: #313A46;
    padding-top: 20px;
    padding-left: 20px;
    .active{
      background: #F2F9FF;
      color: #2385FF;
    }
    .title{
      font-size: 16px;
font-family: PingFang SC;
font-weight: bold;
// color: #313A46;
display: flex;
justify-content: space-around;
line-height: 25px;
padding-bottom: 23px;
::v-deep .el-button {
  min-height: 26px;
  padding: 0 7px;
  margin-left: 20px;
  background:#fff;
  border: 1px solid #437aec;
  color: #437aec;
}
    }
    .list:hover{
      background: #F2F9FF;
      color: #437aec;
    }
    .list {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      padding: 6px 0 6px 6px;
      display: flex;
      align-items: center;
// span {
//   vertical-align: middle;
// }
    }
    .svg-icon {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
  .middle{
    width: 12px;
    height: 100%;
background: #F5F5F5;
border: 1px solid #E8E8E8;
// padding-top: 20%;
display: flex;
    align-items: center;
.control{
  width: 10px;
height: 80px;
padding-top: 24px;
background: #A0E1F9;
}
  }
  .right{
    width: 100%;
    height: 100%;
    overflow: scroll;
    .tabs{
      min-width: 1060px;
      display: flex;
      // position: absolute;
      // left: 0;
      // bottom: 0;
      .tabs-item{
        width: 200px;
        padding: 0 10px;
        // text-align: right;
        justify-content: space-between;
        align-items: center;
        display: flex;
        height: 38px;
        font-size: 16px;
        line-height: 38px;
        background-color: #fff;
        border: 1px solid #D0D0D0;
        border-radius: 4px;
        .svg-icon{
          width: 16px;
          height: 16px;
          background: #E5E5E5;
          border-radius: 50%;
        }
      }
      .activeTab{
        background: #E1E1E1;
      }
      .tabs-item:hover{
        background: #E1E1E1;
      }
      ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item{
        border-bottom: 1px solid #e4e7ed;
      }
      ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
        border-bottom: 1px solid #e4e7ed;
      }
      ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        border-bottom: 1px solid #e4e7ed;
      }
      ::v-deep.el-tabs__item.is-active{
        color: #313A46;
        background: #E1E1E1;
      }
    }
    .list{
      min-width: 1060px;
      padding-left: 20px;
      padding-top: 11px;
      ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
        overflow-x: hidden;
      }
    }
    .header{
      display: flex;
      padding-top: 21px;
      padding-left: 20px;
      padding-bottom: 11px;
      .box{
        margin-right: 20px;
        padding: 31px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 250px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px 0px rgba(2, 34, 74, 0.2);
        border-radius: 2px;
        .image{
          min-width: 48px;
          min-height: 48px;
          margin-right: 18px;
          background: #FFFFFF;
          border: 1px solid #B3BCC7;
          border-radius: 50%;
          position: relative;
          img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
          }
        }
        .text{
          .num{
            font-size: 24px;
            font-weight: bold;
            color: #2385FF;
          }
          .desc{
            font-size: 12px;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
    .form{
      margin-left: 20px;
      display: flex;
      // min-width: 1060px;
      // width: 100%;
      .form-content{
        padding: 9px 0 29px 20px;
        background: rgba(245, 247, 250, 0.5);
        border: 1px solid #E8E8E8;
        min-width: 1030px;
        width: calc(100% - 50px);
        /deep/ .el-form-item--small.el-form-item{
          margin-bottom: 25px;
        }
      }
      .mode{
        width: 30px;
        // height: 80px;
        padding: 5px 0;
        text-align: center;
        background: #F5F5F5;
        border: 1px solid #E8E8E8;
        font-size: 15px;
        font-weight: 500;
        color: #313A46;
      }
      .mode:hover{
        color: #437aec;
      }
      .actives{
        color: #437aec;
      }
      .btn{
        text-align: right;
        margin-bottom: 9px;
        margin-right: 42px;
      }
      ::v-deep .el-button {
      margin-right: 10px;
      background-color: #437aec;
      color: #fff;
      padding: 10px 16px;
      border-radius: 2px;
      min-height: 34px;
    }
    .svg-icon {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 6px;
    }
      .content{
        display: flex;
        .coloumn{
          width: 50%;
          padding-right: 42px;
          // .data-dir{
          //   ::v-deep.el-input__inner{
          //     border: none !important;
          //     background-color: transparent !important;
          //   }
          // }
        }
      }
    }
  }
  }

   ::v-deep .el-dialog{
  border-radius: 20px;
}
::v-deep .el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    ::v-deep .el-dialog__body{
      padding: 0;
    }
    ::v-deep .el-icon{
      color: #333;
    }
}
</style>
<style lang="less">
.el-message-box__header{
  border-bottom: 1px solid #d8d8d8;
}
.atooltip{
  background-color: #F5F5F5 !important;
}
.slurm-address{
  // color: #202020;
  padding-bottom: 3px;
  border-bottom: 1px solid #E8E8E8;
}
.slurm-hover-name:hover{
  color: #437aec;
  .circles{
    background: #2385FF;
  }
}
.slurm-hover-name{
  display: flex;
  align-items: center;
  .circles{
          margin-right: 6px;
          width: 6px;
height: 6px;
background: #BBBBBB;
border-radius: 50%;
        }
}
  .el-popper.is-light{
    background-color: #F5F5F5;
  }
  .el-popper__arrow{
    background-color: #F5F5F5;
  }
  .el-tooltip__popper[x-placement^=right] .popper__arrow:after {
    border-right-color: #F5F5F5 !important;
  }
  .el-tooltip__popper[x-placement^=right] .popper__arrow {
    border-right-color: #F5F5F5 !important;
  }
  .el-popper{
    min-width: 150px;
    font-weight: 500;
    line-height: 2;
  }
.tabs{
  .el-tabs--card>.el-tabs__header{
    border-bottom: none;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom: 1px solid #e4e7ed;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__nav{
    border-bottom: 1px solid #e4e7ed;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
    border-bottom: 1px solid #e4e7ed;
  }
  .el-tabs__item.is-active{
    color: #313A46;
    background: #E1E1E1;
  }
  .el-tabs__item{
    padding: 0 50px;
  }
  .el-tabs__item:hover{
    color: #313A46;
    background: #E1E1E1;
    padding: 0 50px !important;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item.is-active.is-closable{
    padding: 0 50px !important;
  }
}
.data-dir{
  .el-input__inner{
    border: none;
    background-color: transparent;
  }
  input::-webkit-input-placeholder {
    color: #437aec;
  }
  input::-moz-input-placeholder {
    color: #437aec;
  }
  input::-ms-input-placeholder {
    color: #437aec;
  }
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}
</style>
